
import Vue from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
	name: "Toggle",
	props: {
		expanded: {
			type: Boolean,
			required: false,
		},
	},
	components: {},
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {
		...mapGetters("loading", ["isLoading"]),
	},
	methods: {
		handlerClick(event: any) {
			this.$emit("change");
		},
	},
	watch: {},
});
